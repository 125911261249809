var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Servidores")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"p-fluid grid"},[_c('div',{staticClass:"field col-12 md:col-6"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{attrs:{"placeholder":".","data-key":"key","options":_vm.matriculasList},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value)?_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.value.matricula)+" - "+_vm._s(slotProps.value.orgaoNome)+" ")])]):_vm._e()]}},{key:"option",fn:function(slotProps){return [_c('span',{staticClass:"card-desktop"},[_vm._v(" "+_vm._s(slotProps.option.matricula)+" - "+_vm._s(slotProps.option.orgaoNome)+" ")]),_c('span',{staticClass:"card-mobile"},[_vm._v(" "+_vm._s(slotProps.option.matricula)+" - "+_vm._s(_vm._f("limitadorString")(slotProps.option.orgaoNome))+" ")])]}}]),model:{value:(_vm.matriculaValue),callback:function ($$v) {_vm.matriculaValue=$$v},expression:"matriculaValue"}}),_c('label',{attrs:{"for":"matricula"}},[_vm._v("Matrícula")])],1)]),_c('div',{staticClass:"field col-12 md:col-2"},[_c('Button',{attrs:{"label":"Buscar","disabled":_vm.matriculaValue == null},on:{"click":function($event){return _vm.carregarDadosContracheque(
                  _vm.matriculasList[0].cpf,
                  _vm.matriculaValue.id
                )}}})],1)]),(_vm.escondeColunas)?_c('DataTable',{attrs:{"stripedRows":"","value":_vm.contracheques,"loading":_vm.loading,"paginator":true,"filters":_vm.filtros,"globalFilterFields":['orgao', 'dataInclusao', 'Periodo'],"filterDisplay":"menu","rows":10,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} contracheques","responsiveLayout":"stack"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma matrícula encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('span',{staticClass:"mt-2 mr-2 p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"mt-2 mr-2 p-button-outlined",attrs:{"icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})]},proxy:true}],null,false,1714869282)},[_c('Column',{attrs:{"field":"orgao","header":"Orgão","sortable":true}}),_c('Column',{attrs:{"field":"periodo","header":"Competencia"}}),_c('Column',{attrs:{"header":"Visualizar"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var data = ref.data;
return [_c('div',[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Visualizar Contracheque'),expression:"'Visualizar Contracheque'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-success mr-2",attrs:{"placeholder":'Visualizar Contracheque',"icon":"pi pi-file-pdf"},on:{"click":function($event){return _vm.gerarContracheque(data)}}})],1)]}}],null,false,3919901544)})],1):_vm._e(),(!_vm.escondeColunas)?_c('DataTable',{attrs:{"stripedRows":"","value":_vm.contracheques,"loading":_vm.loading,"paginator":true,"filters":_vm.filtros,"globalFilterFields":['orgao', 'dataInclusao', 'Periodo'],"filterDisplay":"menu","rows":10,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} contracheques","responsiveLayout":"stack"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma matrícula encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('span',{staticClass:"mt-2 mr-2 p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"mt-2 mr-2 p-button-outlined",attrs:{"icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})]},proxy:true}],null,false,1714869282)},[_c('Column',{attrs:{"field":"orgao","header":"Orgão","sortable":true}}),_c('Column',{attrs:{"field":"dataInclusao","header":"Data de Inclusão"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(data.dataInclusao)+" ")])]}}],null,false,1714094808)}),_c('Column',{attrs:{"field":"periodo","header":"Competencia"}}),_c('Column',{attrs:{"field":"parcela","header":"Parcela","sortable":true}}),_c('Column',{attrs:{"header":"Visualizar"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var data = ref.data;
return [_c('div',[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Visualizar Contracheque'),expression:"'Visualizar Contracheque'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-success mr-2",attrs:{"placeholder":'Visualizar Contracheque',"icon":"pi pi-file-pdf"},on:{"click":function($event){return _vm.gerarContracheque(data)}}})],1)]}}],null,false,3919901544)})],1):_vm._e()]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }